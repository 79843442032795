import React, {useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {getDatePlusInterval} from '../../Utils/DateUtils';
import {Button, MenuItem, Card, CardContent, FormControl,
  InputLabel, TextField, Snackbar, CircularProgress, Select} from '@material-ui/core';
import {DateRangePickerComponent} from '../../Utils/DatePickers';
import {useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import {ApiClient} from '../../Utils/ApiClient';
import {useContext} from 'react';
import {UserContext} from '../../contexts/userContext';
import {getSnapshotTitle} from '../../Utils/SnapshotHelper';
import {useHistory} from 'react-router-dom';
import {RegularSpacer} from '../BasicComponents/Spacers';

const useStyles = createUseStyles(
    {
      onlyMarginBottom: {
        marginBottom: 10,
      },
    },
);

const ExportPage = () => {
  const [startDate, setStartDate] = useState(() => getDatePlusInterval(-1));
  const [endDate, setEndDate] = useState(() => getDatePlusInterval(0, true));
  const [snapshots, setSnapshots] = useState([]);
  const [filteredSnapshots, setFilteredSnapshots] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [lane, setLane] = useState('');
  const [crop, setCrop] = useState('');
  const [recipeId, setRecipeId] = useState('');
  const [highlightDates, setHighlightDates] = useState(new Set());
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const classes = useStyles();
  const {currentClient, getUserConfig, isADIUser} = useContext(UserContext);

  useEffect(() => {
    history.push((`/${currentClient || 'admin'}/export-page`));
  }, [currentClient]);
  useEffect(() => {
    if (!currentClient) {
      return;
    }
    fetchSnapshots();
  }, [currentClient, startDate, endDate]);

  useEffect(() => {
    if (!snapshots || snapshots.length === 0) {
      return;
    }
    let filteredSnapshots = snapshots;
    if (lane) {
      filteredSnapshots = snapshots.filter((snapshot) => {
        return snapshot.data.lane === lane;
      });
    }
    if (crop) {
      filteredSnapshots = filteredSnapshots.filter((snapshot) => {
        return snapshot.data.crop === crop;
      });
    }
    if (recipeId) {
      filteredSnapshots = filteredSnapshots.filter((snapshot) => {
        return snapshot.data.analysis_pipeline_name === recipeId;
      });
    }
    setFilteredSnapshots(filteredSnapshots);
  }, [snapshots, lane, crop, recipeId]);

  useEffect(() => {
    markDaysWithUploads();
  }, [currentClient]);

  const handleChangeDateStart = (e) => {
    const dateObject = new Date(e);
    dateObject.setHours(0, 0, 0, 0);
    setStartDate(dateObject.toISOString());
  };

  const handleChangeDateEnd = (e) => {
    const dateObject = new Date(e);
    dateObject.setHours(23, 59, 59, 999);
    setEndDate(dateObject.toISOString());
  };

  const fetchSnapshots = async () => {
    setIsLoading(true);
    const snapshots = await ApiClient.findSnapshotsByClient(
        startDate, endDate, 'analyzed', null, true, currentClient);
    setSnapshots(snapshots);
    setIsLoading(false);
  };

  const markDaysWithUploads = async () => {
    setHighlightDates(new Set());
    ApiClient.findDaysWithUploads(currentClient, 'analyzed').then((days) => {
      setHighlightDates(days);
    });
  };

  const handleExport = async () => {
    const result = await ApiClient.getErpExport(filteredSnapshots);
    if (result?.status !== 200) {
      setErrorMessage('Export failed, Server Response Status: ' + result?.status + ' ' + result?.statusText);
      return;
    }
    setErrorMessage('');
    const blob = result.data;
    const type = blob.type;
    const fileExtension = '.' + type.split('/')[1];
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = 'download' + fileExtension;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    setTimeout(() => {
      URL.revokeObjectURL(downloadLink.href);
      document.body.removeChild(downloadLink);
    }, 100);
  };

  const cropPicker = () => {
    const crops = getUserConfig(true)?.export_page?.filter_on_crops;
    if (!crops) {
      return '';
    }
    return <span>
      <RegularSpacer />
      <FormControl fullWidth>
        <InputLabel id="crop-label">Crop</InputLabel>
        <Select
          labelId="crop-picker"
          id="crop-picker-select"
          value={crop}
          label="Crop"
          onChange={(e) => setCrop(e.target.value)}>
          {crops.map((crop) => <MenuItem key={crop + '-crop-picker'}
            id={crop} value={crop}>{crop}</MenuItem>)}
        </Select>
      </FormControl>
    </span>;
  };

  const recipeTextField = () => {
    if (!isADIUser) {
      return '';
    }
    return <span>
      <RegularSpacer />
      <FormControl fullWidth>
        <TextField label="RecipeId" type="text" value={recipeId}
          onChange={(e) => setRecipeId(e.target.value)}
          inputProps={{min: 0, style: {textAlign: 'center'}}} />
      </FormControl>
    </span>;
  };

  return (
    <div style={{'width': '70%', 'display': 'flex', 'flexDirection': 'column',
      'justifyContent': 'center', 'alignItems': 'center'}}>
      <Card style={{'width': '50%', 'marginTop': '20px'}}>
        <CardContent>
          <FormControl fullWidth className={classes.onlyMarginBottom}>
            <DateRangePickerComponent
              id="dateFrom"
              defaultStartDate={startDate}
              defaultEndDate={endDate}
              changeStartDate={(event) => handleChangeDateStart(event)}
              changeEndDate={(event) => handleChangeDateEnd(event)}
              highlightDates={highlightDates}
            />
            {getUserConfig(true)?.export_page?.filter_on_lane ?
            <TextField label="Lane" type="text" value={lane}
              onChange={(e) => setLane(e.target.value)}
              inputProps={{min: 0, style: {textAlign: 'center'}}} /> : ''}
            {cropPicker()}
            {recipeTextField()}
          </FormControl>
        </CardContent>
      </Card>
      <Button variant="contained" color="primary" onClick={handleExport}
        style={{'marginTop': '20px', 'marginBottom': '20px'}}>
          Download Export
      </Button>
      {isLoading ? <CircularProgress/> :
      <div style={{maxHeight: '600px', overflowY: 'scroll'}}>
        <ul>
          {filteredSnapshots.map((snapshot) => (
            <Card key={snapshot._id} className={classes.onlyMarginBottom}>
              <CardContent>
                {getSnapshotTitle(snapshot, true, true)}
              </CardContent>
            </Card>
          ))}
        </ul>
      </div>}
      {errorMessage !== '' ?
      <Snackbar
        autoHideDuration={10000}
        open={open}
        message={errorMessage}
        onClose={() => setErrorMessage('')}
      /> : ''}
    </div>
  );
};

export default ExportPage;
