import React, {useState, useEffect, useCallback, useContext} from 'react';
import {useStateWithCallbackLazy} from 'use-state-with-callback';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DetailedList from './DetailedList';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CommentSection from './CommentSection';
import {ApiClient} from '../../Utils/ApiClient';
import {useTranslation} from 'react-i18next';
import {RootDiv, RootCard, NormalizedGrid, SizedCardContent,
  HeaderText, CommentText, ButtonCard} from './ImagePageStyles';
import SnapshotList from './SnapshotList';
import {dateToRange} from '../../Utils/DateUtils';
import {UserContext} from '../../contexts/userContext';
import {getReadibleDates} from '../../Utils/SnapshotHelper';
import {useParams} from 'react-router-dom';
import {useHistory} from 'react-router-dom';

function ImagePage(props) {
  const {t} = useTranslation();
  const {getUserConfig, currentClient} = useContext(UserContext);

  const [data, setData] = useStateWithCallbackLazy([]);
  const [annotations, setAnnotations] = useState([]);
  const [cropName, setCropName] = useState('');
  const [articleName, setArticleName] = useState('');
  const [plantingDate, setPlantingDate] = useState('');
  const [batchId, setBatchId] = useState('');
  const [currentItemData, setCurrentItemData] = useState({
    mediaCard: {},
    currentIndex: -1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showSnapshotById, setShowSnapshotById] = useState('');
  const params = useParams();
  const history = useHistory();
  useEffect(() => {
    setCropName(currentItemData?.mediaCard?.data?.crop || '');
    setBatchId(currentItemData?.mediaCard?.data?.batch_id || '');
    setArticleName('');
    setPlantingDate('');
    if (currentItemData['mediaCard']._id) {
      history.push(`/${currentClient || 'admin'}/images/${currentItemData['mediaCard']._id}`);
    }
  }, [currentItemData]);

  useEffect(() => {
    if (params.snapshot_id && currentItemData.currentIndex === -1) {
      setShowSnapshotById(params.snapshot_id);
    }
  }, []);

  useEffect(() => {
    if (currentClient !== params.client_name && !getUserConfig(true).annotation_clients) {
      history.push(`/${currentClient || 'admin'}/images`);
    }
  }, [currentClient]);

  function sortAndSetSnapshots(snapshots) {
    const sortedSnapshots = sortSnapshots(snapshots);
    setData(sortedSnapshots);
    loadAnnotations(sortedSnapshots);
  }

  function needsDiseaseInformation() {
    return getUserConfig(true).toggle_buttons?.includes('Disease');
  }

  function updateData(date) {
    if (date === null) {
      return;
    }
    const dateRange = dateToRange(date);
    setIsLoading(true);
    const disease = needsDiseaseInformation();
    ApiClient.findSnapshotsByClient(dateRange[0], dateRange[1],
        null, null, disease, getUserConfig(true).annotation_clients ?
        params.client_name : currentClient).then((snapshots) => {
      setIsLoading(false);
      if (snapshots === undefined || snapshots.length === 0) {
        return;
      }
      sortAndSetSnapshots(snapshots);
    });
  }

  const searchBatch = (batchId) => {
    ApiClient.findSnapshotsByBatchId(batchId, getUserConfig(true).annotation_clients ?
    params.client_name : currentClient).then((snapshots) => {
      if (snapshots === undefined || snapshots.length === 0) {
        return;
      }
      sortAndSetSnapshots(snapshots);
    });
  };

  function loadAnnotations(data) {
    const idList = data.map((obj) => obj._id);
    ApiClient.findAnnotationsBySnapshotIds(idList).then((annotations) => {
      setAnnotations(annotations);
    });
  }

  function sortSnapshots(data) {
    const sortBy = getUserConfig(true).sort_by;
    if (sortBy === 'batch') {
      data.sort((a, b) => (a.data.batch_id > b.data.batch_id) ? 1 : -1);
      return data;
    } else if (['sbris_listing', 'mdc_listing'].includes(sortBy)) {
      data.sort((a, b) => {
        const {lane: laneA, sub_lane: subLaneA, position_id:
            {hook: hookA, distance_previous_hook_rounded: distA}} = a.data;
        const {lane: laneB, sub_lane: subLaneB, position_id:
            {hook: hookB, distance_previous_hook_rounded: distB}} = b.data;
        const laneAInt = parseInt(laneA);
        const laneBInt = parseInt(laneB);
        if (laneAInt !== laneBInt) return laneAInt - laneBInt;
        if (subLaneA !== subLaneB) return subLaneA.localeCompare(subLaneB);
        if (hookA !== hookB) return hookA - hookB;
        return distA - distB;
      });
      return data;
    } else if (sortBy === 'collection_date') {
      console.log('sorting by collection date');
      data.sort((a, b) => (new Date(a.collection_date) > new Date(b.collection_date)) ? 1 : -1);
      return data;
    } else {
      data.sort((a, b) => (new Date(a.modified) > new Date(b.modified)) ? 1 : -1);
      return data;
    }
  }

  const checkValidChange = (dataLength, index, operation) => {
    if (operation === 'next') {
      return index !== dataLength - 1;
    } else if (operation === 'previous') {
      return index !== 0;
    }
    return false;
  };

  const handleClickNext = () => {
    if (!(checkValidChange(data.length, currentItemData.currentIndex, 'next'))) {
      return;
    }
    setCurrentItemData((prevItemData) => ({
      mediaCard: data[parseInt(prevItemData.currentIndex) + 1],
      currentIndex: parseInt(prevItemData.currentIndex) + 1,
    }));
  };

  const handleClickPrevious = () => {
    if (!(checkValidChange(data.length, currentItemData.currentIndex, 'previous'))) {
      return;
    }
    setCurrentItemData((prevItemData) => ({
      mediaCard: data[parseInt(prevItemData.currentIndex) - 1],
      currentIndex: parseInt(prevItemData.currentIndex) - 1,
    }));
  };

  const handleChangeIndex = (item) => {
    setCurrentItemData({
      mediaCard: item,
      currentIndex: item.index,
    });
  };

  const handleChangeDataList = useCallback((newList) => {
    setData(newList);
  }, []);

  const loadInstanceProperties = (instance) => {
    if (instance?.crop && instance.crop !== '') {
      setCropName(instance.crop);
    } else if (instance?.plant_information?.article_group &&
      instance.plant_information.article_group !== '') {
      setCropName(instance.plant_information.article_group);
    }
    if (instance?.plant_information?.batch_id && instance.plant_information.batch_id !== '') {
      setBatchId(instance.plant_information.batch_id);
    }
    if (instance?.plant_information?.article_name && instance.plant_information.article_name !== '') {
      setArticleName(instance.plant_information.article_name);
    }
    if (instance?.plant_information?.planting_date) {
      setPlantingDate(instance.plant_information.planting_date);
    }
  };

  const changeSnapshotStatus = (snapshotId, status) => {
    const newData = data.map((snapshot) => {
      if (snapshot._id === snapshotId) {
        snapshot.status = status;
      }
      return snapshot;
    });
    setData(newData);
  };

  return (
    <RootDiv>
      <NormalizedGrid container spacing={2}>
        <Grid item xs={3}>
          <HeaderText variant="h5">
                        Trays
          </HeaderText>
          <SnapshotList data={data} annotations={annotations} changeIndex={handleChangeIndex}
            changeData={handleChangeDataList} updateData={updateData} baseUrl={props.baseUrl}
            index={currentItemData.currentIndex} searchBatch={searchBatch} credentials={props.credentials}
            showAnnotations={props.annotations} isLoading={isLoading} snapshotId={showSnapshotById}
            setSnapshotId={setShowSnapshotById}/>
        </Grid>
        <Grid item xs={props.annotations ? 9 : 7}>
          <HeaderText variant="h5">
            {t('Detailed Information')}
          </HeaderText>
          <ButtonCard>
            <Button onClick={handleClickPrevious}>{t('Previous')}</Button>
            <Button onClick={handleClickNext}>{t('Next')}</Button>
          </ButtonCard>
          <DetailedList annotations={props.annotations} data={currentItemData.mediaCard}
            baseUrl={props.baseUrl} loadInstanceProperties={loadInstanceProperties}
            changeSnapshotStatus={changeSnapshotStatus} />
        </Grid>
        {props.annotations ? <></> :
                    <Grid item xs={2}>
                      <HeaderText variant="h5">
                        {t('Crop Information')}
                      </HeaderText>
                      <RootCard>
                        <Paper />
                        {currentItemData.mediaCard.data ?
                                <SizedCardContent>
                                  <Typography variant="body2" color="textSecondary" component="p">
                                    {'Batch: ' + batchId}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary" component="p">
                                    {t('Crop') + ': ' + cropName}
                                  </Typography>
                                  {articleName ?
                                        <Typography variant="body2" color="textSecondary" component="p">
                                          {t('Article') + ': ' + articleName}
                                        </Typography> : ''}
                                  {currentItemData.mediaCard.data.article ?
                                        <Typography variant="body2" color="textSecondary" component="p">
                                          {t('Article') + ': ' + currentItemData.mediaCard.data.article}
                                        </Typography> : ''
                                  }
                                  {plantingDate ?
                                    <Typography variant="body2" color="textSecondary" component="p">
                                      {t('Planting date') + ': ' + getReadibleDates(new Date(plantingDate))}
                                    </Typography> : ''}
                                </SizedCardContent> :
                                ''}
                      </RootCard>
                      {
                        currentItemData.mediaCard.data ?
                            <>
                              <CommentText variant="h5">
                                {t('Comment')}
                              </CommentText>
                              <RootCard>
                                <Paper />
                                <CommentSection data={currentItemData.mediaCard} sbris={true}
                                  notifyADIDefault={getUserConfig()?.notify_adi} />
                              </RootCard>
                            </> :
                            ''
                      }
                    </Grid>}
      </NormalizedGrid>
    </RootDiv>
  );
}

export default ImagePage;
